import { ChangeEvent, FC, useCallback, useContext, useState } from 'react'
import orderBy from 'lodash/orderBy'
import { useRouter } from 'next/router'
import { Text, Loader, Icon, Modal, Input } from '@/client/components'
import { CartContext } from '@/client/context/cart'
import { useGetPrices, useMutatePrices } from '@/client/hooks/prices'
import { format } from '@/client/utils'
import * as S from './styles'
import { ResultFooter } from '../ResultFooter'
import { ResultProps, schema, Steps } from '../types'
import { z } from 'zod'
import { useDebounce, useGetUserInfos } from '@/client/hooks'

export const Result: FC<ResultProps> = ({ result, step, setStep, setIsAlertOpen, texts, error_messages }) => {
  const { locale } = useRouter()
  const { isLoading, mutate } = useMutatePrices(locale)
  const { updateSelection, selection } = useContext(CartContext)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const { data } = useGetPrices(locale)
  const { mutate: updatePrices } = useMutatePrices(locale)
  const [isClosing, setIsClosing] = useState<boolean>(false)
  const { data: userInfos, isLoading: userInfosLoading } = useGetUserInfos()
  const [custName, setCustName] = useState<string>('')
  const [custEmail, setCustEmail] = useState<string>('')
  const debouncedValue = useDebounce(custName, 500)
  const debouncedValueEmail = useDebounce(custEmail, 500)

  let formErrors = ''

  const selectQuantity = useCallback(
    (quantity) => {
      const newSelection = { ...selection, quantity }
      updateSelection(newSelection)
      mutate(newSelection)
    },
    [updateSelection, selection, mutate]
  )

  const selectDeliveryType = useCallback(
    (type) => {
      const newSelection = { ...selection, express_production: type }
      updateSelection(newSelection)
      mutate(newSelection)
    },
    [updateSelection, selection, mutate]
  )

  const handleSubmitForm = useCallback(() => {
    let initialSelection = selection
    let newSelection = { ...selection }

    newSelection.quotation_options = {
      mail_language: locale,
      shop_id: 'es',
      save_quotation: 1,
      express_delivery: 0,
      cust_name: debouncedValue,
      cust_mail: debouncedValueEmail,
      cust_id: userInfos?.customer_id ?? 0,
      delivery_country: userInfos?.country_id ?? 15,
      core_diameter: 0,
      roll_max_labels: 0,
      shipment: 0,
      discount_abs: 0,
      discount_percent: 0,
      surcharge: 0,
      proof_free: 0,
      proof_type: 0,
      artwork_service_free: 0,
      artwork_service_type: 0
    }

    updateSelection(newSelection)
    updatePrices(newSelection)
    setIsModalOpen(false)

    setIsClosing(true)

    setTimeout(() => {
      setIsModalOpen(false)
      setIsClosing(false)

      updateSelection(initialSelection)
      updatePrices(initialSelection)
    }, 1200)
  }, [updatePrices, updateSelection, selection, debouncedValueEmail, debouncedValue])

  const handleValidateForm = useCallback(() => {
    const formSchema = schema(error_messages)

    try {
      formSchema.parse({
        quotation_cust_name: debouncedValue,
        quotation_cust_mail: debouncedValueEmail
      })

      formErrors = ''
      return false
    } catch (e) {
      const errors = {}
      if (e instanceof z.ZodError) {
        e.errors.forEach((error) => {
          errors[error.path[0]] = error.message
        })
      }

      formErrors = errors['quotation_cust_name'] || errors['quotation_cust_mail']

      return true
    }
  }, [debouncedValue, debouncedValueEmail])

  const getPriceByQuantity = useCallback((): string => {
    const newPrice = result?.priceTable?.find((item) => item.quantity === Number(selection?.quantity))
    return !!newPrice?.totalPrice ? format.currency(newPrice?.totalPrice) : '--  €'
  }, [result?.priceTable, selection?.quantity])

  const getUnityPrice = useCallback(() => {
    const unityPrice = result.priceTable?.find(({ quantity }) => Number(selection?.quantity) === Number(quantity))
    return format.currency(unityPrice?.unitPrice || 0)
  }, [result.priceTable, selection?.quantity])

  const disableNextStep =
    !(result.priceTable?.length > 0) ||
    (step === Steps.OPTIONS && (!selection.order_name || selection.artwork_service === 0))

  const closeModalWithDelay = useCallback(() => {
    setIsClosing(true)
    setTimeout(() => {
      setIsModalOpen(false)
      setIsClosing(false)
    }, 1000)
  }, [])

  return (
    <S.Result>
      <S.ResultWrapper>
        <S.ResultBox shadow>
          {isLoading && (
            <S.BoxShadow>
              <Loader color='inverse' size='large' />
            </S.BoxShadow>
          )}
          <Text size='xlg' family='heading' weight='bold' align='center' margin='0 0 2rem'>
            {texts.title}
          </Text>

          <S.Total>{getPriceByQuantity()}</S.Total>

          <Text color='weak' size='sm' margin='0 0 2rem'>
            {texts.unitary_price}: {getUnityPrice()}
          </Text>
          {result.numberOfSheets > 0 && (
            <Text color='weak' size='sm' margin='0 0 2rem'>
              {texts.number_sheets}: {result.numberOfSheets}
            </Text>
          )}
          <S.Table>
            <S.TableHead>
              <S.TableCell>{texts.table.quantity}</S.TableCell>
              <S.TableCell>{texts.table.total_price}</S.TableCell>
              <S.TableCell>{texts.table.unitPrice}</S.TableCell>
            </S.TableHead>

            {orderBy(result.priceTable, ['quantity']).map(({ quantity, totalPrice, unitPrice }) => (
              <S.TableRow
                key={quantity}
                role='button'
                tabIndex={0}
                highlight={Number(selection?.quantity) === Number(quantity)}
                onClick={() => selectQuantity(quantity)}
              >
                <S.TableCell>{quantity}</S.TableCell>
                <S.TableCell>{format.currency(totalPrice)}</S.TableCell>
                <S.TableCell>{format.currency(unitPrice)}</S.TableCell>
              </S.TableRow>
            ))}
          </S.Table>

          <S.Delivery className='tour-delivery'>
            <S.DeliveryType
              role='button'
              tabIndex={0}
              selected={selection?.express_production === false}
              onClick={() => selectDeliveryType(false)}
            >
              <Icon name='gear-production-standard' size='s5' />
              <div>
                <Text size='xs' color='weak' margin='0 0 .25rem'>
                  {texts.deliver.standard}
                </Text>
                <Text size='sm' margin='0'>
                  {result.dateStandard}
                </Text>
              </div>
            </S.DeliveryType>

            <S.DeliveryType
              role='button'
              tabIndex={0}
              selected={selection?.express_production === true}
              onClick={() => selectDeliveryType(true)}
            >
              <Icon name='productionexpress' size='s5' />
              <div>
                <Text size='xs' color='weak' margin='0 0 .25rem'>
                  {texts.deliver.express}
                </Text>
                <Text size='sm' margin='0'>
                  {result.dateExpress}
                </Text>
              </div>
            </S.DeliveryType>
          </S.Delivery>

          <S.QuotationMailSender role='button' tabIndex={0} onClick={() => setIsModalOpen(true)}>
            <Icon name='email-messages' size='s4' color='#008FBE' />
            <Text size='sm' color='primary' weight='bold'>
              {'   '}
              {texts.quotation.function_name}
            </Text>
          </S.QuotationMailSender>

          <S.QuotationContainer>
            {isModalOpen && (
              <div className={`modal ${isClosing ? 'modal-hidden' : 'modal-open'}`}>
                <Modal
                  size='medium'
                  infos={{
                    title: texts.quotation.title,
                    subtitle: texts.quotation.subtitle
                  }}
                  close={closeModalWithDelay}
                  func={handleSubmitForm}
                  isDisabled={handleValidateForm()}
                  buttonName={texts.quotation.button}
                  cancelButtonName={''}
                  className={'background'}
                  overflow={false}
                >
                  <S.AddressForm>
                    <Input
                      isBlock
                      dimension='small'
                      type='text'
                      id='quotation_cust_name'
                      name='quotation_cust_name'
                      placeholder={texts.quotation.name}
                      value={custName}
                      onChange={(e) => {
                        setCustName(e?.currentTarget?.value)
                      }}
                    />
                    <Input
                      isBlock
                      dimension='small'
                      type='text'
                      id='quotation_cust_mail'
                      name='quotation_cust_mail'
                      placeholder={texts.quotation.email}
                      value={custEmail}
                      onChange={(e) => {
                        setCustEmail(e?.currentTarget?.value)
                      }}
                    />
                  </S.AddressForm>
                </Modal>
              </div>
            )}
          </S.QuotationContainer>

          <ResultFooter
            step={step}
            setStep={setStep}
            setIsAlertOpen={setIsAlertOpen}
            texts={{
              button: texts.button,
              extra: texts.extra,
              help: texts.help,
              more_info: texts.more_info
            }}
            isLoading={isLoading}
            disableNextStep={disableNextStep}
          />
        </S.ResultBox>
        <Text size='xs' color='weak' margin='1rem 0' italic>
          {texts.footer_message}
        </Text>
      </S.ResultWrapper>
    </S.Result>
  )
}
