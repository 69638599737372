import styled from 'styled-components'
import { theme } from '@/client/components'
import font from '@/client/components/theme/font'
const { color, breakpoint, space } = theme

export const ResultFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: ${space.xl2};
  padding: 0 1rem;
`

export const NavButtons = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${space.md};
  flex-direction: column;

  @media (min-width: ${breakpoint.xl}) {
    flex-direction: row;
  }
`

export const ExtraInfos = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${space.md};
  margin-top: ${space.lg};
`
export const ExtraInfosItem = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${color.grayscale.dark};

  &:hover {
    text-decoration: none;
  }

  .icon {
    margin-right: ${space.xs2};
  }
`
export const ExtraInfosItemSmall = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${color.grayscale.dark};

  &:hover {
    text-decoration: none;
  }

  .icon {
    margin-right: ${space.xs2};
    &:before {
      vertical-align: middle;
    }
  }
`
export const Paragraph = styled.p`
  padding-top: ${space.lg};
  color: ${color.grayscale.dark};
  font-size: ${font.size.xsmall};
  line-height: ${font.lineHeight.md};
  height: 1rem;
`
