import { FC, useCallback, useContext } from 'react'
import { Button, Icon, Text } from '@/client/components'
import { CartContext } from '@/client/context/cart'
import {
  useGetPrices,
  useMutateAddCartItem,
  useMutatePrices,
  useMutateUpdateCartItem,
  useRouterPush
} from '@/client/hooks'
import { ROUTER_PATH } from '@/client/utils'
import * as S from './styles'
import { updateInitialSelection } from '../helper'
import { ResultFooterProps, Steps } from '../types'

export const ResultFooter: FC<ResultFooterProps> = ({
  step,
  setStep,
  setIsAlertOpen,
  texts,
  isLoading,
  disableNextStep
}) => {
  const { routerPush, locale, defaultLocale } = useRouterPush()
  const { data } = useGetPrices(locale)
  const { selection, updateSelection } = useContext(CartContext)
  const { mutate: addCartItem } = useMutateAddCartItem()
  const { mutate: updateCartItem } = useMutateUpdateCartItem()
  const { mutate: updatePrices } = useMutatePrices(locale)

  const handleBackStep = useCallback(() => {
    if (step > Steps.SIZE_AND_FORMAT) {
      window.scrollTo(0, 400)
      setStep(step - 1)
    }
  }, [setStep, step])

  const handleStep = useCallback(() => {
    window.scrollTo(0, 400)
    if (step !== Steps.SUMMARY) {
      setStep(step + 1)
    }

    if (step === Steps.SUMMARY) {
      const { _id, ...rest } = selection

      const newSelection = updateInitialSelection(data?.initialSelection)
      updateSelection(newSelection)
      updatePrices(newSelection)

      if (_id) {
        updateCartItem({
          cartItemId: _id,
          payload: { ...rest }
        })
        routerPush(ROUTER_PATH.CART[defaultLocale])
      } else {
        addCartItem(rest)
        setIsAlertOpen(true)
        setStep(Steps.SIZE_AND_FORMAT)
      }
    }
  }, [
    step,
    setStep,
    setIsAlertOpen,
    selection,
    updateSelection,
    data?.initialSelection,
    updateCartItem,
    addCartItem,
    updatePrices,
    routerPush,
    defaultLocale
  ])

  return (
    <S.ResultFooter>
      <S.NavButtons>
        <Button
          kind='weak'
          id={step !== 1 ? 'calc_b_prev_result' + `${step - 1}` : 'calc_b_prev_result'}
          onClick={handleBackStep}
          disabled={isLoading || step === Steps.SIZE_AND_FORMAT}
          isBlock
        >
          <Icon name='arrow-left' />
          {texts?.button?.previous_button}
        </Button>
        <Button
          kind='warning'
          id={'calc_b_next_result' + step}
          onClick={handleStep}
          disabled={isLoading || disableNextStep}
          isBlock
        >
          {step !== Steps.SUMMARY && (
            <>
              {texts?.button?.state_1}
              <Icon name='arrow-right' />
            </>
          )}
          {step === Steps.SUMMARY && (
            <>
              <Icon name='cart' />
              {texts?.button?.state_2}
            </>
          )}
        </Button>
      </S.NavButtons>

      <S.ExtraInfos>
        <S.ExtraInfosItem href={texts?.extra?.file_link} target='_blank'>
          <Icon name='data-sheet' size='s4' />
          <Text size='xs' color='weak' margin='0' lineHeight='1.3'>
            {texts?.extra?.file_label}
          </Text>
        </S.ExtraInfosItem>
      </S.ExtraInfos>

      <S.Paragraph
        dangerouslySetInnerHTML={{ __html: texts.more_info.info_sentence }}
        style={{ fontSize: '0.75rem' }}
      />

      <S.ExtraInfos>
        <S.ExtraInfosItemSmall href={texts?.help?.file_link} target='_blank'>
          <Text size='xs' color='weak' margin='0' lineHeight='1.3'>
            <Icon name='info' size='s4' />
            {texts?.help?.file_label}
          </Text>
        </S.ExtraInfosItemSmall>
      </S.ExtraInfos>
    </S.ResultFooter>
  )
}
